import { Box } from "@mui/material";
import moment from "moment";
import { getCompany } from "../../../actions/auth";


export default [
    {
        field: "timestamp",
        headerName: "Fecha llamada",
        description: "Fecha y hora",
        width: 190,
        renderCell: (params) => {
            
            return moment(new Date((params.value))).format('L LTS')

        }
    },
    {
        field: "phoneNumber",
        headerName: "Teléfono",
        description: "Teléfono",
        width: 150,
        renderCell: (params) => {
            if(params.row.prospectId || !getCompany().hideNotClient){
                return params.value
            }else if(params.value && params.value.length > 4){
                return new Array(params.value.length-3).join('#')+params.value.substring(params.value.length-4, params.value.length)
            }else{
                return ''
            }
        }
    },
    {
        field: "name",
        headerName: "Nombre",
        description: "Nombre",
        width: 200,
        renderCell: (params) => {
            if(params.row.prospectName || params.row.prospectId){
                return params.row.prospectName
            }else if(!getCompany().hideNotClient){
                return params.value
            }else{
                return 'No es cliente'
            }
        }
    },
    {
        field: "duration",
        headerName: "Duración",
        description: "Duración en minutos de la llamada",
        width: 90,
        renderCell: (params) => {
            let dateObj = new Date(params.value * 1000);
            let hours = dateObj.getUTCHours();
            let minutes = dateObj.getUTCMinutes();
            let seconds = dateObj.getSeconds();
            let timeString;
            if(hours > 0){
                timeString = hours.toString() + ':' +
                minutes.toString().padStart(2, '0') + ':' +
                seconds.toString().padStart(2, '0');
            }else{
                timeString = 
                minutes.toString() + ':' +
                seconds.toString().padStart(2, '0');
            }
            

            return timeString;
        }
    },
    {
        field: "type",
        headerName: "Tipo",
        description: "Tipo",
        width: 100,
        renderCell: (params) => {
            let value = '';
            switch (params.value) {
                case 'INCOMING':
                    value = 'Entrante';
                    break;
                case 'MISSED':
                    value = 'Perdida'
                    break;
                case 'UNKNOWN':
                    value = 'Desconocida';
                    break;
                case 'OUTGOING':
                    value = 'Saliente'
                    break;
            }
            return value
        }
    },
    {
        field: "user",
        headerName: "Vendedor",
        description: "Vendedor",
        width: 200,
        sortable: false,
        renderCell: (params) => {
            return params.value  ? params.value.name + " " + params.value.lastname : ''
        }
    },
]