import { ApexOptions } from "apexcharts";

interface configMe {
    title: string;
    id?: string;
    groupTitle?: string;
    disableAnimation?:boolean
}

type graphGenerator = (
    configMe,
    categories: string[],
    versionid: number
) => ApexOptions;

const bar: graphGenerator = ({ title, id, groupTitle, disableAnimation }, _, versionid) => {
    let result: ApexOptions = {
        responsive: [
            {
                breakpoint: 1025,
                options: {
                    legend: {
                        show: true,
                        position: "bottom",
                        offsetY: 0,
                        width: undefined,
                        height: 50,
                    },
                },
            },
            {
                breakpoint: 8000,
                options: {
                    legend: {
                        show: true,
                        position: "right",
                        offsetY: 40,
                        width: 150,
                        height: undefined,
                        inverseOrder: true,
                    },
                },
            },
        ],

        legend: {
            show: true,
            position: "right",
            offsetY: 40,
            width: 150,
            inverseOrder: true,
            showForSingleSeries: true,
        },
        chart: {
            id: id
                ? id + versionid + groupTitle
                : title + versionid + groupTitle,
            type: "bar",
            stacked: true,
            toolbar: {
                show: true,
            },
            zoom: {
                enabled: true,
            },
            ...(disableAnimation ?
                {
                    animations: {
                enabled: false 
            }
        } : {})
        },
        dataLabels: {
            enabled: true,
            formatter: function (value, _) {
                if (typeof value === "number") return Math.floor(value);
                if (typeof value === "string") {
                    let alt_value = parseInt(value as string);
                    if (isNaN(alt_value)) return value;
                    return Math.floor(alt_value);
                } else {
                    return value.join(", ");
                }
            },
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (val, opts) => {
                    let total = 0;
                    opts.series.map(
                        (val) => (total += val[opts.dataPointIndex])
                    );
                    let percent = Math.floor((val / total) * 100);
                    return `${Math.floor(val)} (${percent}%)`;
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
            },
        },
        xaxis: {
            // type: 'category',
            categories: [],
        },

        // annotations: {
        //   points: [
        //     {
        //       x: '15 julio 2022',
        //       y: 30,
        //       label: {
        //         style: {
        //           background: "rgba(0,0,0,0)",
        //         },
        //         borderColor: 'rgba(0,0,0,0)',
        //         text: "test"
        //       }
        //     }
        //   ]
        // },

        yaxis: {
            decimalsInFloat: 0,
        },
        title: {
            text: title,
        },
        fill: {
            opacity: 1,
        },
        noData: {
            text: "No hay datos disponibles",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#0080ff",
                fontSize: "20px",
            },
        },
    };
    return result;
};

export default bar;
