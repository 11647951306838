import { ApexOptions } from "apexcharts";

interface configMe {
    title: string;
    id?: string;
}

type pieGenerator = (
    configMe,
    categories: string[],
    versionid: number
) => ApexOptions;

const pie: pieGenerator = ({ title, id }, _, versionid) => ({
    responsive: [
        {
            breakpoint: 1025,
            options: {
                legend: {
                    show: true,
                    position: "bottom",
                    offsetY: 0,
                    width: undefined,
                    height: 50,
                },
            },
        },
        {
            breakpoint: 8000,
            options: {
                legend: {
                    show: true,
                    position: "right",
                    offsetY: 40,
                    width: 150,
                    height: undefined,
                    inverseOrder: true,
                },
            },
        },
    ],
    // plotOptions: {
    //   pie: {
    //     donut: {
    //       size: '62%'
    //     }
    //   }
    // },
    legend: {
        show: true,
        position: "right",
        offsetY: 40,
        width: 150,
        inverseOrder: true,
    },

    title: {
        text: title,
    },

    dataLabels: {
        enabled: true,
        dropShadow: {
            enabled: true,
            opacity: 0.08,
        },
    },
    chart: {
        id: id ? id + versionid : title + versionid,
        type: "pie",
    },
    grid: {
        padding: {
            top: -5,
        },
    },
    labels: ["Resultado"],
    noData: {
        text: "No hay datos disponibles",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
            color: "#0080ff",
            fontSize: "20px",
        },
    },
});

export default pie;
