import { Box } from "@mui/material";
import moment from "moment";
import { getCompany } from "../../../actions/auth";


export default [
    {
        field: "date",
        headerName: "Fecha",
        description: "Fecha",
        width: 150,
        renderCell: (params) => {
            return moment(params.value).format("DD/MM/YYYY");
        }
    },
    {
        field: "phone",
        headerName: "Teléfono",
        description: "Teléfono",
        width: 150,
        renderCell: (params) => {
            if(!getCompany().hideNotClient || params.row.validity != 'not_client'){
                return params.value
            }else if(params.value && params.value.length > 4){
                return new Array(params.value.length-3).join('#')+params.value.substring(params.value.length-4, params.value.length)
            }else{
                return ''
            }
        }
    },
    {
        field: "prospectName",
        headerName: "Nombre contacto",
        description: "Nombre contacto",
        width: 150,
        renderCell: (params) => {
            if(!getCompany().hideNotClient || params.row.validity != 'not_client'){
                return params.value
            }else{
                return 'No es cliente'
            }
        }
    },
    {
        field: "maxDuration",
        headerName: "Duración",
        description: "Duración llamada más larga al cliente",
        width: 100,
        renderCell: (params) => {
            
            let dateObj = new Date(params.value * 1000);
            let hours = dateObj.getUTCHours();
            let minutes = dateObj.getUTCMinutes();
            let seconds = dateObj.getSeconds();
            let timeString;
            if(hours > 0){
                timeString = hours.toString() + ':' +
                minutes.toString().padStart(2, '0') + ':' +
                seconds.toString().padStart(2, '0');
            }else{
                timeString = 
                minutes.toString()+ ':' +
                seconds.toString().padStart(2, '0');
            }
            

            return timeString;
        }
    },
    {
        field: "validity",
        headerName: "Valido",
        description: "Valido",
        width: 150,
        renderCell: (params) => {
            switch(params.value){
                case 'not_time':
                    return <Box color="#ef5350">Tiempo insuficiente</Box>
                case 'not_answer':
                    return <Box color="#ef5350">Sin respuesta</Box>
                case 'not_client':
                    return <Box color="#ef5350">Sin cliente</Box>
                case 'not_record':
                    return <Box color="#ef5350">Sin registro</Box>
                case 'valid':
                    return <Box color="#4caf50">Valido</Box>
            }
            return params.value
        }
    },
    {
        field: "user",
        headerName: "Vendedor",
        description: "Vendedor",
        width: 150,
        renderCell: (params) => {
            return params.value ? `${params.value.name} ${params.value.lastname}` : '';
        }
    },
    {
        field: "noteContent",
        headerName: "Nota",
        description: "Nota",
        width: 300,
        renderCell: (params) => {
            if(params.value){
                return params.value;
            }else{
                return ''
            }
        }
    },
]