import fetch from 'isomorphic-fetch'
import {getCookie} from './auth'
var API = process.env.API


export const getGraphData = (data, source) => {
    return fetch(`${API}/graph/${source}?test=true`,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        },
        body: JSON.stringify(data)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const getActiveUsers = () => {
    return fetch(`${API}/graph/activeusers`,{
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        },
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}